// authentication
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const LAST_SIGNIN = 'LAST_SIGNIN';

// messages
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const RESET_MESSAGE = 'RESET_MESSAGE';

// progress
export const LOADING = 'LOADING';
export const LOADING_SUCCESS = 'LOADING_SUCCESS';
export const LOADING_ERROR = 'LOADING_ERROR';

// Settings
export const SET_COLOR = 'SET_COLOR';
export const SET_COOKIES = 'SET_COOKIES';
export const SET_COOKIES_OPEN = 'SET_COOKIES_OPEN';

// Recipe
export const GET_RECIPE = 'GET_RECIPE';
export const SET_RECIPE_SETTINGS = 'SET_RECIPE_SETTINGS';
export const SET_RECIPE_ID = 'SET_RECIPE_ID';
export const ADD_RECIPE_PICTURE = 'ADD_RECIPE_PICTURE';
export const SET_RECIPE_FAVORITE = 'SET_RECIPE_FAVORITE';
export const SET_RECIPE_NOTE = 'SET_RECIPE_NOTE';

// Images
export const SET_IMAGES = 'SET_IMAGES';

// Recipe Filter
export const GET_RECIPES = 'GET_RECIPES';
export const SET_WORD = 'SET_WORD';
export const SET_TYPE = 'SET_TYPE';
export const FILTER_OPEN = 'FILTER_OPEN';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_SORT = 'SET_SORT';
export const RESET_RECIPES_FILTER = 'RESET_RECIPES_FILTER';
export const SET_ROUTE = 'SET_ROUTE';
export const SET_AUTHOR = 'SET_AUTHOR';

// Recipe Formular
export const SET_RECIPE_ERROR = 'SET_RECIPE_ERROR';
export const SET_RECIPE_TITLE = 'SET_RECIPE_TITLE';
export const SET_RECIPE_PORTION = 'SET_RECIPE_PORTION';
export const ADD_RECIPE_KEYWORDS = 'ADD_RECIPE_KEYWORDS';
export const REMOVE_RECIPE_KEYWORDS = 'REMOVE_RECIPE_KEYWORDS';
export const SET_RECIPE_INGREDIENTS = 'SET_RECIPE_INGREDIENTS';
export const SET_RECIPE_STEPS = 'SET_RECIPE_STEPS';
export const SET_RECIPE_PICTURES = 'SET_RECIPE_PICTURES';
export const SET_RECIPE_TIME = 'SET_RECIPE_TIME';
export const SET_RECIPE_FORMULAR = 'SET_RECIPE_FORMULAR';
export const SET_RECIPE_BLOCKED = 'SET_RECIPE_BLOCKED';
export const SET_RECIPE_FORMULAR_UPLOADED = 'SET_RECIPE_FORMULAR_UPLOADED';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SET_RECIPE_CREDITS = 'SET_RECIPE_CREDITS';

export const SET_SAVED_RECIPE_FORMULAR = 'SET_SAVED_RECIPE_FORMULAR';
